import React, { useEffect, useState } from 'react'
import { searchButtons, CardViewTableData } from './data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaMagnifyingGlassPlus } from 'react-icons/fa6'
import { MdFavoriteBorder } from 'react-icons/md'
import { MdOutlineFavorite } from 'react-icons/md'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { MdDelete } from 'react-icons/md'
import { GoReport } from 'react-icons/go'
import { useSelector, useDispatch } from 'react-redux'
import {
  deleteFavorite,
  createFavorite,
} from '../../redux/Favorites/favorites.actions'
import { deleteProduct } from '../../redux/Products/products.actions'
import PuffLoader from 'react-spinners/PuffLoader'
import Tooltip from '../Shared/Tooltip'
import ImageModal from './ImageModal'

const CardViewItems = ({ item, page }) => {
  const user = useSelector((state) => state.user.currentUser)
  const favorites = useSelector((state) => state.favoritesData.favorites)
  const loading = useSelector((state) => state.favoritesData.loading)
  const dispatch = useDispatch()
  const [currentItemAction, setCurrentItemAction] = useState(null)
  const [open, setOpen] = useState(false)
  const [isFav, setIsFav] = useState(false)

  const isUserAdmin = user?.userRoles.includes('admin')

  useEffect(() => {
    const isFavExist = favorites?.find((fav) => fav?.UPC === item?.UPC)
    setIsFav(isFavExist ? true : false)
  }, [item, favorites])

  useEffect(() => {
    !loading && setCurrentItemAction(null)
  }, [loading])

  const handleAction = () => {
    setCurrentItemAction(item?.UPC)
    if (page === 'products' && !isFav) {
      const newFavorite = {
        userId: user?.id,
        UPC: item?.UPC,
      }
      dispatch(createFavorite(newFavorite))
    } else {
      dispatch(deleteFavorite({ UPC: item?.UPC, userId: user?.id }))
    }
  }

  const handleDelete = (id) => {
    // Only admin user can delete a product
    if (id) dispatch(deleteProduct(id))
  }

  return (
    <div className='relative card-container md:min-w-[640px] lg:min-w-[725px] lg:max-w-[1100px] bg-white p-1 flex flex-col lg:flex-row rounded-xl shadow-md border-1 hover:shadow-red-500'>
      <div
        className={`group remove-container ${page !== 'products' && 'hidden'} absolute top-2 left-1.5 rounded-full p-1.5 text-xl text-gray-600 hover:text-red-500 hover:bg-gray-200 active:scale-95 z-10 cursor-pointer transition-colors duration-200`}
        onClick={handleAction}
      >
        {currentItemAction === item.UPC && loading ? (
          <PuffLoader size={20} color='#c11600' />
        ) : page === 'products' ? (
          isFav ? (
            <MdOutlineFavorite className='text-red-500' />
          ) : (
            <MdFavoriteBorder />
          )
        ) : (
          <MdDelete />
        )}
        <span className='group-hover:visible opacity-0 group-hover:opacity-100 invisible absolute top-1 left-9 rounded-md shadow-md px-2 py-1 bg-gray-800 text-xs text-white w-max max-w-64 z-10 transition-opacity duration-700'>
          {page === 'products'
            ? isFav
              ? 'Remove Favorite'
              : 'Add To Favorites'
            : 'Remove Favorite'}
        </span>
      </div>
      <div className='group relative lg:w-1/4 px-3 h-full hidden lg:flex justify-center items-center'>
        <img
          src={item?.modProductThumbnail}
          alt='Image'
          className='h-auto max-h-[380px] object-contain'
        />
        <FaMagnifyingGlassPlus
          className='group-hover:block hidden absolute cursor-pointer opacity-85 text-2xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
          onClick={() => setOpen(true)}
        />
      </div>
      <div className='w-full lg:w-3/4'>
        <div className='flex lg:mb-4'>
          <div className='group relative w-2/5 md:w-1/4 max-h-32 px-3 flex lg:hidden justify-center items-center'>
            <img
              src={item?.modProductThumbnail}
              alt='Image'
              className='h-[100%] object-contain'
            />
            <FaMagnifyingGlassPlus
              className='group-hover:block absolute cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-85 text-2xl hidden'
              onClick={() => setOpen(true)}
            />
          </div>
          <div className='w-full'>
            <div className='flex flex-wrap justify-between'>
              <p>{item?.category}</p>
              <div className='flex'>
                <p>{`Last Update: ${item?.lastUpdated}`}</p>
                <div
                  className={`report-delete invisible flex justify-center w-16 ${page !== 'products' && 'hidden'}`}
                >
                  <div className='relative w-1/2'>
                    <GoReport className='peer mx-auto size-6 text-amber-500 hover:text-amber-600 active:size-5 cursor-pointer' />
                    <span className='absolute -right-5 top-7 invisible peer-hover:visible rounded-md shadow-md px-2 py-1 bg-gray-800 text-xs text-white w-max z-10 transition-visibility'>
                      Report Product
                    </span>
                  </div>
                  <div className={`relative w-1/2 ${!isUserAdmin && 'hidden'}`}>
                    <RiDeleteBin5Line
                      onClick={() => handleDelete(item?.id)}
                      className='peer size-6 mx-auto pb-0.5 text-red-500 hover:text-red-600 active:size-5 cursor-pointer'
                    />
                    <span className='absolute right-0 top-7 invisible peer-hover:visible rounded-md shadow-md px-2 py-1 bg-gray-800 text-xs text-white w-max z-10 transition-visibility'>
                      Delete Product
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <h6 className='font-semibold'>{item?.name}</h6>
            <p className='text-green-600 font-semibold'>
              {item?.modProfit !== 'N/A' && `+${item?.modProfit}`}
              <span className='text-xs text-red-800'>
                {item?.modROI !== 'N/A' && ` (ROI: ${item?.modROI})`}
              </span>
            </p>
          </div>
        </div>
        <hr className='mx-5 mt-2 md:mt-0' />
        <div className='min-h-[200px] p-2 pt-4 flex flex-col md:flex-row gap-2 md:gap-0 justify-center items-start'>
          <div className='w-full md:w-1/2 flex flex-col items-center'>
            <div className='pt-3 mb-2'>
              <a href={item.buyLink} target='_blank' rel='noopener noreferrer'>
                <div className='relative w-64 border border-gray-200 rounded-md shadow-md hover:shadow-gray-500 text-center cursor-pointer transition-shadow duration-300'>
                  <h6 className='transition-colors duration-300 absolute w-max px-1 bg-white -top-3.5 left-1/2 transform -translate-x-1/2'>
                    Marketplaces To Buy
                  </h6>
                  <h6 className='mt-3 mb-2 font-semibold'>{`${item?.modBuyPrice} ${item?.modBuyMarket && `in ${item?.modBuyMarket}`}`}</h6>
                </div>
              </a>
            </div>
            <h6>
              Pack of <span className='font-bold'>{item?.modPackOf}</span>
            </h6>
            <h6>
              Estimated Tax:
              <span className='font-bold'> {item?.modEstimatedTax}</span>
            </h6>
            <div className='hidden md:flex justify-between w-60 mt-3'>
              {searchButtons.map((button, index) => (
                <a
                  key={index}
                  href={item[button.URL]}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='group relative flex justify-center items-center text-white bg-sky-600 rounded-lg text-md w-16 h-7 cursor-pointer shadow-md hover:bg-[#f39200] active:scale-95 '
                >
                  <FontAwesomeIcon icon={button.icon} />
                  <span className='group-hover:visible group-hover:delay-700 invisible absolute bottom-8 rounded-md shadow-md px-2 py-1 bg-gray-800 text-xs text-white w-max max-w-64  z-10 transition-visible duration-300'>
                    {button.tooltipText}
                  </span>
                </a>
              ))}
            </div>
          </div>
          <div className='w-full md:w-1/2 flex flex-col items-center'>
            <div className='pt-3 mb-3'>
              <a href={item.sellLink} target='_blank' rel='noopener noreferrer'>
                <div className='relative w-64 border border-gray-200 rounded-md shadow-md hover:shadow-gray-500 text-center cursor-pointer transition-shadow duration-300'>
                  <h6 className='transition-colors duration-300 absolute w-max px-1 bg-white -top-3.5 left-1/2 transform -translate-x-1/2'>
                    Marketplaces To Sell
                  </h6>
                  <h6 className='mt-3 mb-2 font-semibold'>{`${item?.modSellPrice} ${item?.sellMarket && `in ${item?.sellMarket}`}`}</h6>
                </div>
              </a>
            </div>
            <h6>
              Pack of <span className='font-bold'> {item?.modPackOf}</span>
            </h6>
            <h6>
              Best Sellers Rank:
              <span className='font-bold'> {item?.modSellRank}</span>
            </h6>
            <h6>
              Unit Sales Per Month:
              <span className='font-bold'> {item?.modUnitSales}</span>
            </h6>
            <h6>
              Number of Sellers:
              <span className='font-bold'> {item?.numOfSellers}</span>
            </h6>
          </div>
          <div className='flex md:hidden justify-center gap-8 w-full mt-3'>
            {searchButtons.map((button, index) => (
              <a
                key={index}
                href={item[button.URL]}
                target='_blank'
                rel='noopener noreferrer'
                className='group relative flex justify-center items-center text-white bg-sky-600 rounded-lg text-md w-16 h-7 cursor-pointer shadow-md hover:bg-[#f39200] active:scale-95 '
              >
                <FontAwesomeIcon icon={button.icon} />
                <span className='group-hover:visible group-hover:delay-700 invisible absolute bottom-8 rounded-md shadow-md px-2 py-1 bg-gray-800 text-xs text-white w-max max-w-64  z-10 transition-visible duration-300'>
                  {button.tooltipText}
                </span>
              </a>
            ))}
          </div>
        </div>
        <div className='min-h-[90px]'>
          <div className='mx-1 lg:mx-5 shadow-md rounded-lg overflow-hidden text-sm md:text-base'>
            <table className='w-full border-collapse '>
              <thead className='bg-gray-700 text-gray-50'>
                <tr>
                  {CardViewTableData.map((data) => (
                    <th
                      key={data.field}
                      className='h-10 px-1 md:min-w-24 border border-gray-300 text-center font-semibold'
                    >
                      {data.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {CardViewTableData.map((data) => (
                    <td
                      key={data.field}
                      className='group relative cursor-pointer h-10 px-1 md:min-w-24 border border-gray-300 text-center'
                    >
                      <Tooltip tooltipText={item[data?.tooltipText]}>
                        <div className='w-full h-full flex items-center justify-center'>
                          {item[data.field]}
                        </div>
                      </Tooltip>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {open && (
        <ImageModal {...{ open, setOpen, image: item?.modProductThumbnail }} />
      )}
    </div>
  )
}

export default CardViewItems

import { combineReducers } from 'redux'
import userReducer from './User/user.reducer'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import productsReducer from './Products/products.reducer'
import cartReducer from './Cart/cart.reducer'
import favoritesReducer from './Favorites/favorites.reducer'

export const rootReducer = combineReducers({
  user: userReducer,
  productsData: productsReducer,
  cartData: cartReducer,
  favoritesData: favoritesReducer,
})

const configStorage = {
  key: 'root',
  storage,
  whitelist: ['cartData'],
}

export default persistReducer(configStorage, rootReducer)

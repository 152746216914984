const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT

export const checkout = (customerId) => {
  fetch(ENDPOINT + '/api/v1/stripe/create-subscription-checkout-session', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      plan: 1,
      customerId: customerId,
    }),
  })
    .then((res) => {
      if (res.ok) return res.json()
      console.log(res)
      return res.json().then((json) => Promise.reject(json))
    })
    .then(({ session }) => {
      window.location = session.url
    })
    .catch((e) => {
      console.log(e.error)
    })
}

export const subscriptionCancel = (subscriptionId, customerId) => {
  fetch(ENDPOINT + '/api/v1/stripe/cancel-subscription', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      subscriptionId: subscriptionId,
      customerId: customerId,
    }),
  })
    .then((res) => {
      if (res.ok) return res.json()
      console.log(res)
      return res.json().then((json) => Promise.reject(json))
    })
    .then(({ session }) => {
      window.location = session.url
    })
    .catch((e) => {
      console.log(e.error)
    })
}

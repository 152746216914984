export const existingCartItem = ({ prevCartItems, nextCartItem }) => {
  return prevCartItems.find(
    (cartItem) => cartItem.documentID === nextCartItem.documentID
  )
}

export const handleAddToCart = ({ prevCartItems, nextCartItem }) => {
  const quantityIncrement = 1
  const cartItemExists = existingCartItem({ prevCartItems, nextCartItem })

  prevCartItems = prevCartItems.map(
    (cartItem) =>
      (cartItem = {
        ...cartItem,
        lastItem: null,
      })
  )

  if (cartItemExists) {
    return prevCartItems.map((cartItem) =>
      cartItem.documentID === nextCartItem.documentID
        ? {
            ...cartItem,
            quantity: cartItem.quantity + quantityIncrement,
            lastItem: true,
          }
        : {
            ...cartItem,
          }
    )
  }
  return [
    ...prevCartItems,
    {
      ...nextCartItem,
      quantity: quantityIncrement,
      lastItem: true,
    },
  ]
}

export const handleRemoveCartItem = ({ prevCartItems, cartItemToRemove }) => {
  return prevCartItems.filter(
    (item) => item.documentID !== cartItemToRemove.documentID
  )
}

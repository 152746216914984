import { createFavorite } from '../../redux/Favorites/favorites.actions'
import { removeCartItem } from '../../redux/Cart/cart.actions'

export const syncFavorites = (
  currentUser,
  oldFavorites,
  favorites,
  dispatch
) => {
  if (!oldFavorites?.length || !currentUser) return
  oldFavorites.forEach((oldFav) => {
    const isFavExist = favorites?.find((fav) => fav?.UPC === oldFav?.UPC)
    if (isFavExist) return
    const syncFav = {
      userId: currentUser?.id,
      UPC: oldFav.UPC,
    }
    dispatch(createFavorite(syncFav))
    dispatch(removeCartItem({ documentID: oldFav.documentID }))
  })
}
